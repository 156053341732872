import Head from "next/head";
import { FC } from "react";
import {
  BUSSINESS_DESCRIPTION,
  BUSSINESS_KEYWORDS,
  BUSSINESS_TAGLINE,
  BUSSINESS_URL,
  META,
  replaceShortcodes,
} from "../../project/project.info";
import { useRouter } from "next/router";
import { emptyString } from "../storyblok/components/content/Content";

interface Props {
  story: any;
}

const Meta: FC<Props> = (props) => {
  const { story } = props;
  const router = useRouter();

  const pageMeta = {
    title: story?.content?.metaTitle,
    tagline: story?.content?.metaTagline,
    description: story?.content?.metaDescription,
    keywords: story?.content?.metaKeywords?.join(", "),
    author: story?.content?.metaAuthor,
  };
  const metaSettings = META;
  const canonicalUrl = (
    BUSSINESS_URL + (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0];

  const shortcodes: {
    [key: string]: string;
  } = {
    "page-name": story.name as string,
    "page-author": story?.content?.author as string,
    "page-description": story?.content?.description as string,
    "site-name": metaSettings.title as string,
    "site-tagline": BUSSINESS_TAGLINE,
    "site-description": BUSSINESS_DESCRIPTION,
  };

  const values = replaceShortcodes(pageMeta, shortcodes);

  const titleSpot = `${values.title} ${
    emptyString(values.tagline) ? "" : ` | ${values.tagline}`
  }`;

  const structureData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: values.title,
    description: values.description,
    telephone: "0261711520",
    address: {
      "@type": "PostalAddress",

      streetAddress: "102, 27 Lonsdale Street",
      addressLocality: "Braddon",
      addressRegion: "ACT",
      postalCode: "2602",
      addressCountry: "AU",
    },
    author: [
      {
        "@type": "Person",
        name: values.author,
      },
    ],
    // image: post.imageUrl,
    datePublished: story?.published_at,
  };

  return (
    <Head>
      <title>{titleSpot}</title>
      <link rel="canonical" href={canonicalUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1,viewport-fit=cover"
      />
      <meta property={metaSettings.ogTitle.property} content={titleSpot} />
      <meta
        property={metaSettings.ogUrl.property}
        content={metaSettings.ogUrl.content}
      />
      <meta
        property={metaSettings.ogDescription.property}
        content={values.description}
      />
      <meta name={metaSettings.description.name} content={values.description} />
      <meta name={metaSettings.keywords.name} content={values.keywords} />
      <meta
        property={metaSettings.ogType.property}
        content={metaSettings.ogType.content}
      />
      <meta
        property={metaSettings.ogImage.property}
        content={metaSettings.ogImage.content}
      />

      <link
        rel={metaSettings.favicon16.rel}
        type={metaSettings.favicon16.type}
        sizes={metaSettings.favicon16.sizes}
        href={metaSettings.favicon16.href}
      />
      <link
        rel={metaSettings.favicon32.rel}
        type={metaSettings.favicon32.type}
        sizes={metaSettings.favicon32.sizes}
        href={metaSettings.favicon32.href}
      />
      <link
        rel={metaSettings.favicon192.rel}
        type={metaSettings.favicon192.type}
        sizes={metaSettings.favicon192.sizes}
        href={metaSettings.favicon192.href}
      />
      <link
        rel={metaSettings.favicon512.rel}
        type={metaSettings.favicon512.type}
        sizes={metaSettings.favicon512.sizes}
        href={metaSettings.favicon512.href}
      />
      <link
        rel={metaSettings.faviconIco.rel}
        href={metaSettings.faviconIco.href}
      />
      <link
        rel={metaSettings.appleTouchIcon.rel}
        type={metaSettings.appleTouchIcon.type}
        href={metaSettings.appleTouchIcon.href}
      />

      <meta name="theme-color" content={metaSettings.themeColor} />
      <meta
        name="apple-mobile-web-app-status-bar"
        content={metaSettings.appleMobileWebAppStatusBarColor}
      />
      <link rel="manifest" href="/manifest.json" />
      <meta
        name="google-site-verification"
        content="WtbW1N3rNk6fyWXveAo8vLHosLcsuliJy7Mxb0wLV7c"
      />
      <script
        key="structured-data"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structureData) }}
      />
    </Head>
  );
};

export default Meta;
